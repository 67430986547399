import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Grid,
  Typography,
  StepConnector,
  FormHelperText,
  RadioGroup,
  Radio,
  Divider,
  Button,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { validateInput } from "../../common/utils/validationUtils";
import { PATTERNS } from "../../common/utils/constant";
import {
  DescriptionInput,
  BootstrapInput,
} from "../styledCcomponents/StyledMenu";
import CustomInputLabel from "../styledCcomponents/styledInput";
import OtpInput from "react-otp-input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  goalOptions,
  durationOptions,
} from "../../common/constant/NotifictionData";
import {
  useCountries,
  useStates,
  useCities,
  useCampaignGoals,
  useBrandCategary,
} from "../customHooks/customHook";
import {
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MarqetingEndpoint from "../../Services/marqetingEndpoint";
import { postFormData, postJsonData } from "../../Services/AxoisInterceptorr";
import { toast } from "react-toastify";
import Animations from "../../common/Skeleton/Skeleton";
import CheckboxComponent from "../checkbox";
import RegisterSuccessPopup from "../RegisterPopup";

export default function MyStepperBrands() {
  const [collaborationBudget, setCollaborationBudget] = useState("");
  const [otherCategory, setOtherCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isOtherCity, setIsOtherCity] = useState(false);

  const [formData, setFormData] = useState({
    firstname: {
      value: "",
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
    brandname: {
      value: "",
      isValid: true,
    },
    websiteurl: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [otherCity, setOtherCity] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(false);
  const [goals, setGoals] = useState("");
  const [categories, setCategories] = useState({});
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const countries = useCountries();
  const [timer, setTimer] = useState(30);
  const states = useStates(selectedCountryIso2);
  const cities = useCities(age);
  const campaighgoals = useCampaignGoals();
  const categorie = useBrandCategary();
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handlesetCountryChange = (event) => {
    setSelectedCountryIso2(event.target.value);
  };
  const navigate = useNavigate();
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity == "Other") {
      setIsOtherCity(true);
      setCity("Other");
    } else {
      setCity(selectedCity);
      setIsOtherCity(false);
    }
  };
  const navigateToHome = () => {
    navigate("/");
  };
  const handleOtherCityChange = (event) => {
    setOtherCity(event.target.value);
  };
  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const selectedCategories = Object.keys(categories).filter(
    (category) => categories?.[category]
  );
  const selectedCountryName = countries?.find(
    (country) => country.name === selectedCountryIso2
  )?._id;
  const selectedStateName = states?.find((state) => state.name === age)?._id;
  const selectedGoals = campaighgoals?.find(
    (state) => state.title === goals
  )?._id;
  const handleLogin = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!isChecked) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    const isFormValid =
      !otpVerified &&
      Object.values(formData).every((field) => field.isValid) &&
      Object.values(formData).every((field) => field.value.trim() !== "") &&
      duration.trim() !== "" &&
      goals.trim() !== "" &&
      (city || otherCity) &&
      Object.values(categories).some((category) => category);
    if (isFormValid) {
      const jsonData = {
        fullName: formData.firstname.value,
        email: formData.email.value,
        mobileNumber: formData.phone.value,
        country: selectedCountryName,
        state: selectedStateName,
        city: city === "Other" ? null : city,
        brandName: formData.brandname.value,
        brandCategory: selectedCategories,
        brandWebsite: formData.websiteurl.value,
        campaignRequirement: selectedGoals,
        collaborationBudget: collaborationBudget,
        estimatedCampaignStartDate: duration,
        otherCategory: otherCategory,
        otherCity: isOtherCity ? otherCity : undefined,
      };

      postJsonData(
        MarqetingEndpoint.BRAND_CREATE,
        jsonData,
        () => {},
        (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            // toast.success("Brand created successfully");
            // toast.success(res.data.message);
            setIsSuccess(true); // Set isSuccess to true
            setIsPopupOpen(true)
          }
        },
        (error) => {
          // console.log("loki",error)
          setIsError(true);
          setIsPopupOpen(true);
          // toast.error(error.response.data.message);

          // if (error.response.status === 500) {
          //   toast.error("Internal Server Error");
          // } else if (error.response.status === 409) {
          //   toast.error(
          //     "The requested resource was not found. Please try again."
          //   );
          // }
        }
      );
    }
  };

  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setShowError(false);
  };

  const handleRequestOtp = () => {
    if (!formData.phone.value || formData.phone.value.length < 10) {
      toast.error("Kindly enter your phone number before requesting an OTP.");
      return;
    }
    const phoneNumberWithCountryCode = "91" + formData.phone.value;
    const jsonData = {
      mobileNumber: +phoneNumberWithCountryCode,
    };

    postJsonData(
      MarqetingEndpoint.SEND_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setOtp("");
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    setShowOtpInput(true);
    setPhoneNumberDisabled(true);
  };
  const handleResendtOtp = () => {
    setTimer(30);
    const phoneNumberWithCountryCode = "91" + formData.phone.value;
    const jsonData = {
      mobileNumber: +phoneNumberWithCountryCode,
    };

    postJsonData(
      MarqetingEndpoint.RESEND_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setOtp("");
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    setShowOtpInput(true);
    setPhoneNumberDisabled(true);
  };

  const handleEditPhoneNumber = () => {
    setShowOtpInput(false);
    setPhoneNumberDisabled(false);
  };
  const handleOtpSubmit = () => {
    const phoneNumberWithCountryCode = "91" + formData.phone.value;
    const jsonData = {
      mobileNumber: parseInt(phoneNumberWithCountryCode),
      otp: parseInt(otp),
    };

    postJsonData(
      MarqetingEndpoint.VERIFY_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setShowOtpInput(false);
          setPhoneNumberDisabled(true);
          setOtpVerified(true);
        }
      },
      (error) => {
        toast.error(error.response.data.message);
        setOtp("");
        setShowOtpInput(true);
      }
    );
  };

  const handleOtherCategoryChange = (event) => {
    setOtherCategory(event.target.value);
  };
  const handleBudgetChange = (event) => {
    setCollaborationBudget(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menuProps = {
    MenuProps: {
      PaperProps: {
        style: {
          width: "400px",
          marginTop: "20px",
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
      },
    },
  };
  const handleClick = () => {
    setSubmitClicked(true);
  };

  return (
    <>
      <RegisterSuccessPopup
    open={isPopupOpen}
    navigateToHome={() => {
          navigateToHome();
          setIsPopupOpen(false); 
        }}
        brand = {false}
    isSuccess={isSuccess}
    isError={isError}
  />
 
    <Box sx={{ width: "100%" }}>
      <Box>
        <h1 className="brandinfluncers_forms">Brand Enquiry</h1>

        <>
          {" "}
          <form onSubmit={handleLogin}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Full Name*</CustomInputLabel>
                  <DescriptionInput
                    required
                    name="first_name"
                    type="text"
                    placeholder="Enter Full Name"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "firstname", PATTERNS.PERSONNEW_NAME);
                    }}
                  />
                  {formData.firstname.isValid || (
                    <p className="input_text_error">
                      *Please enter your full name.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Email Address*</CustomInputLabel>
                  <DescriptionInput
                    required
                    name="first_name"
                    type="text"
                    placeholder="Enter Email Address"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "email", PATTERNS.EMAIL);
                    }}
                  />
                  {formData.email.isValid || (
                    <p className="input_text_error">
                      *Please enter your valid email address.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <div className="mobile_function">
                    <CustomInputLabel>Phone Number*</CustomInputLabel>
                    {/* {showOtpInput ? (
                      <div
                        className="align_self"
                        onClick={handleEditPhoneNumber}
                      >
                        <h5>Edit Number</h5>
                      </div>
                    ) : (
                      <div className="align_self">
                        {otpVerified ? (
                          <h6>Verified</h6>
                        ) : (
                          <div onClick={handleRequestOtp}>
                            <h5>Request OTP</h5>
                          </div>
                        )}
                      </div>
                    )} */}
                  </div>

                  <DescriptionInput
                    required
                    name="Contact"
                    type="Number"
                    placeholder="Enter Phone Number"
                    id="outlined-required"
                    label="First Name"
                    helper
                    disabled={phoneNumberDisabled}
                    size="small"
                    onChange={(e) => {
                      setValues(e, "phone", PATTERNS.MOBILE);
                    }}
                  />
                  {formData.phone.isValid || (
                    <p className="input_text_error">
                      *Please enter valid phone number.
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {showOtpInput && (
              <>
                {" "}
                <div className="otp-container">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputStyle="otp-input"
                    renderSeparator={<span className="otp-separator">-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="submt_content">
                  <div className="resendcontainer">
                    <Button
                      sx={{
                        color: "#163e6e",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      size="small"
                      variant="text"
                      onClick={handleResendtOtp}
                      disabled={timer > 0}
                    >
                      Resend OTP
                    </Button>

                    {timer > 0 && (
                      <span className="timerresendtext">
                        Resend in {timer} seconds
                      </span>
                    )}
                  </div>
                  <div className="submi_content_button">
                    <button
                      className="otpsubmitbutton"
                      type="button"
                      onClick={handleOtpSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Country Name*</CustomInputLabel>

                  <FormControl>
                    <Select
                      value={selectedCountryIso2}
                      onChange={handlesetCountryChange}
                      displayEmpty
                      input={<BootstrapInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        color: "#000000",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value=""
                      >
                        <em>Select Country</em>
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.name}
                          value={country.name}
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                </FormControl>
                {formSubmitted && !selectedCountryIso2 && (
                  <p className="input_text_error">*Please select a country.</p>
                )}
              </Grid>

              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>State/UT Name*</CustomInputLabel>

                  <FormControl>
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      size="small"
                      input={<BootstrapInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        color: "#000000",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value=""
                      >
                        <em>Select State/UT</em>
                      </MenuItem>
                      {states.map((state) => (
                        <MenuItem
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                          value={state.name}
                          key={state.name}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                </FormControl>
                {formSubmitted && !age && (
                  <p className="input_text_error">*Please select a state/UT.</p>
                )}
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>City Name*</CustomInputLabel>

                  <FormControl>
                    <Select
                      value={isOtherCity ? "Other" : city}
                      onChange={handleCityChange}
                      displayEmpty
                      size="small"
                      input={<BootstrapInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        color: "#000000",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value=""
                      >
                        <em>Select City</em>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value="Other"
                      >
                        <em>Enter Other City</em>
                      </MenuItem>
                      {cities.map((city) => (
                        <MenuItem
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                          value={city._id}
                          key={city._id}
                        >
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {isOtherCity &&
                      selectedCountryName &&
                      selectedStateName && (
                        <DescriptionInput
                          required
                          sx={{ marginTop: "10px" }}
                          value={otherCity}
                          onChange={handleOtherCityChange}
                          size="small"
                          placeholder="Enter your city"
                        />
                      )}

                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                </FormControl>
                {formSubmitted && !city && !isOtherCity && (
                  <p className="input_text_error">*Please select a city.</p>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Brand Name*</CustomInputLabel>

                  <DescriptionInput
                    required
                    name="brandname"
                    type="text"
                    placeholder="Enter Brand Name"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "brandname", PATTERNS.PERSONNEW_NAME);
                    }}
                  />
                  {formData.brandname.isValid || (
                    <p className="input_text_error">
                      *Please enter valid brand name.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Brand Website*</CustomInputLabel>

                  <DescriptionInput
                    required
                    name="website"
                    type="text"
                    placeholder="Enter Brand Website"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "websiteurl", PATTERNS.WEBSITE_URL);
                    }}
                  />
                  {formData.websiteurl.isValid || (
                    <p className="input_text_error">
                      *Please enter valid website url.
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            <FormControl component="fieldset">
              <CustomInputLabel>Brand Category*</CustomInputLabel>

              <FormGroup>
                <Grid container spacing={3}>
                  {categorie.map((category, index) => {
                    const checked = categories[category._id];
                    return (
                      <Grid item xs={12} sm={2.4} key={index}>
                        <div
                          className={`checkbox_upper_back ${
                            checked ? "checked_background" : ""
                          }`}
                          onClick={() => handleCategoryChange(category._id)}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={() =>
                                  handleCategoryChange(category._id)
                                }
                                style={{
                                  color: checked ? "#FFFFFF" : "",
                                  display: "none",
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  color: checked ? "#ffffff" : "#000000",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  fontFamily: '"Montserrat", sans-serif',
                                }}
                              >
                                {category.brandCategory}
                              </span>
                            }
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={3}>
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="other_category"
                        type="text"
                        placeholder="Enter Other Category"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        value={otherCategory}
                        onChange={handleOtherCategoryChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {formSubmitted &&
                  !(
                    Object.values(categories).some((category) => category) ||
                    otherCategory
                  ) && (
                    <p className="input_text_error">
                      *Please select at least one category.
                    </p>
                  )}
              </FormGroup>

              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
            </FormControl>
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <CustomInputLabel>Campaign Requirement*</CustomInputLabel>

                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={goals}
                    onChange={(e) => setGoals(e.target.value)}
                    displayEmpty={true}
                    input={<BootstrapInput />}
                    renderValue={(selected) =>
                      selected === "" ? "Select Goal" : selected
                    }
                    IconComponent={ExpandMoreIcon}
                    {...menuProps}
                  >
                    {campaighgoals.map((option) => (
                      <MenuItem key={option._id} value={option.title}>
                        <div className="campaign_goal">
                          <Radio
                            color="default"
                            checked={goals === option.title}
                            sx={{
                              color: "#6E7191",
                              "&.Mui-checked": {
                                color: "#163E6E",
                              },
                              display: "none",
                            }}
                          />
                          <div className="campaigngoal_label">
                            <label htmlFor={option.title}>{option.title}</label>
                            <p>{option.description}</p>
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formSubmitted && !goals && (
                  <p className="input_text_error">
                    *Please select a campaign requirement.
                  </p>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>
                    Estimated Campaign Start date*
                  </CustomInputLabel>

                  <Select
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    displayEmpty
                    size="small"
                    input={<BootstrapInput />}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {durationOptions.map((option) => (
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formSubmitted && !duration && (
                  <p className="input_text_error">
                    *Please select a campaign start date.
                  </p>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            <FormControl component="fieldset">
              <CustomInputLabel>Campaign Budget(in Rs.)?*</CustomInputLabel>

              <RadioGroup
                row
                aria-label="collaboration-budget"
                name="collaboration-budget"
                value={collaborationBudget}
                onChange={handleBudgetChange}
              >
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="below-50000"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationBudget === "below-50000" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Below 50,000
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="50000-250000"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationBudget === "50000-250000" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      50,000 - 2,50,000
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="250000-500000"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationBudget === "250000-500000"
                          ? "selected"
                          : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      2,50,000 - 5,00,000
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="above-500000"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationBudget === "above-500000" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Above 5,00,000
                    </Typography>
                  }
                />
              </RadioGroup>
              {formSubmitted && !collaborationBudget && (
                <p className="input_text_error">
                  *Please select a campaign budget
                </p>
              )}

              <Divider sx={{ marginY: 1, borderBottom: "none" }} />
            </FormControl>

            {/* {submitClicked && !otpVerified && (
              <p className="input_text_error" style={{ textAlign: "center" }}>
                Please verify your number first.
              </p>
            )} */}
            <div className="signup_checkbox">
              <CheckboxComponent
                label=""
                value="termsPrivacy"
                name="termsPrivacy"
                onChange={handleCheckboxChange}
                checked={isChecked}
                id="termsPrivacyCheckbox"
              />
              <p>I agree to the </p>
              <span>
                <a target="_blank" href="https://www.marqeting.in/terms">
                  Terms & Conditions
                </a>
              </span>
            </div>
            {formSubmitted &&
              (!age ||
                !selectedCountryIso2 ||
                (!city && !isOtherCity) ||
                !(
                  Object.values(categories).some((category) => category) ||
                  otherCategory
                ) ||
                !goals ||
                !collaborationBudget) && (
                <p className="input_text_error" style={{ textAlign: "center" }}>
                  There are some errors, please check!
                </p>
              )}

            {showError && (
              <p className="input_text_error" style={{ textAlign: "center" }}>
                *Please agree to the Terms & Privacy before signing up.
              </p>
            )}
            <div className="submit_container">
              <button
                type="submit"
                className="contact_submit"
                // onClick={handleClick}
              >
                {/* <button type="submit" className="contact_submit"> */}
                Submit
              </button>
            </div>
          </form>
        </>
      </Box>
    </Box>
    </>
  );
}
