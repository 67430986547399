
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation,useNavigate } from "react-router-dom";
import { Link as ScrollLink ,scroller } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { MarqetingLogoSidebar } from "../IconsImport";

function LandingNavbar() {
  const [click, setClick] = useState(false);

  const navigate = useNavigate();
  const handleClick = () => {
    setClick(!click);
  };

  
 
  const handleWorkClick = () => {
    navigate("/#whytowork");
    setClick(!click);
    setTimeout(() => {
      scroller.scrollTo("whytowork", {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 1000);
  };
  const handleofferClick = () => {
    navigate("/#whatweoffer");
    setClick(!click);
    setTimeout(() => {
      scroller.scrollTo("whatweoffer", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 1000);
  };
  const handleinfluencersClick = () => {
    navigate("/#influencers");
    setClick(!click);
    setTimeout(() => {
      scroller.scrollTo("influencers", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 1000);
  };



  const navLinks = [
    {
      to: "/",
      name: "Home",
      isScrollLink: false,
    },
    {
      to: "whytowork",
      name: "Why To Work",
      isScrollLink: true,
      onClick: handleWorkClick,
    },
    {
      to: "whatweoffer",
      name: "What We Offer",
      isScrollLink: true,
      onClick: handleofferClick
    },
    {
      to: "influencers",
      name: "Influencers",
      isScrollLink: true,
      onClick: handleinfluencersClick
    },
    {
      to: "/solutions",
      name: "Solutions",
      isScrollLink: false,
    },
    // {
    //   to: "/blog",
    //   name: "Blog",
    //   isScrollLink: false,
    // },
    {
      to: "/contactus",
      name: "Contact Us",
      isScrollLink: false,
    },
  ];
  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.location.reload();
  }
  
  return (
    // <div className="hcontainer">

    <nav className="LnavbarPublic">
      <div className="lnav-container">
        <RouterLink  onClick={handleLogoClick}>
          {/* <img className="nav-logo" src={Twopersonlogo} /> */}
          <img className="nav-logo" src={MarqetingLogoSidebar} />
        </RouterLink>

        <ul className="nav-menuh">
          {navLinks.map((link, index) => (
            <li className="nav_item" key={index}>
              {link.isScrollLink ? (
                <ScrollLink
                  to={link.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav_links"
                  onClick={link.onClick}

                >
                  {link.name}
                </ScrollLink>
              ) : (
                <RouterLink to={link.to} className="nav_links">
                  {link.name}
                </RouterLink>
              )}
            </li>
          ))}
        </ul>
        <ul className={click ? "nav-menu Aactive" : "nav-menu"}>
          {navLinks.map((link, index) => (
            <li className="nav-item" key={index}>
              {link.isScrollLink ? (
                <ScrollLink
                  to={link.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-links"
                  onClick={link.onClick}

                >
                  {link.name}
                </ScrollLink>
              ) : (
                <RouterLink
                  to={link.to}
                  className="nav-links"
                  onClick={handleClick}
                >
                  {link.name}
                </RouterLink>
              )}
            </li>
          ))}
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          {click ? (
            <CloseIcon
              sx={{ width: "32px", height: "32px", color: "#163e6e" }}
            />
          ) : (
            <MenuIcon
              sx={{ width: "32px", height: "32px", color: "#163e6e" }}
            />
          )}
        </div>
      </div>
    </nav>
      // </div>
  );
}

export default LandingNavbar;

